import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import GGFXImage from "@components/elements/GGFXImage"
import React, { useEffect } from "react"
import "./Banner.scss"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import BannerContent from "./BannerContent"
import BannerMenu from "./BannerMenu"

const BannerLanding = ({
  bannerData,
  ggfx_results,
  strapiID,
  menuData,
  team,
  breadcrumbURLs,
  dontShowCTAIcon,
  office,
  isGuide,
  isOffice
}) => {
  const { reset } = usePropertyStore((state) => ({ reset: state.reset }))
  useEffect(() => {
    reset()
  }, [])

  return (
    <div className="banner-wrap banner-landing-wrap">
      <BannerMenu />
      <div className="bg-section">
        {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={bannerData?.banner_image?.url} alt="banner-bg" /> */}
        <GGFXImage
          ImageSrc={bannerData?.banner_image}
          altText="banner-bg"
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename={isOffice ? "office.bg_image.banner_img" : isGuide ? "area-guide.banner_image.banner_img" : "page.banner_image.banner_img"}
          strapiID={strapiID}
        />
        <div className="overlay" />
      </div>
      <Breadcrumbs menuData={menuData} staticURLs={breadcrumbURLs} whiteColor />
      <BannerContent
        bannerData={bannerData}
        ggfx_results={ggfx_results}
        strapiID={strapiID}
        team={team}
        office={office}
        bannerlanding
        dontShowCTAIcon={dontShowCTAIcon}
      />
    </div>
  )
}

export default BannerLanding
